<template>
  <div>
    <section class="page-title text-center">
      <div class="container">
        <div class="content-box">
          <h1>{{ $t('Services') }}</h1>
        </div>
      </div>
    </section>
    <products
      v-if="services && services.length > 0 && massage.length > 0"
      :list="services"
      :list2="massage"
      :service="false"
    />
  </div>
</template>

<script>
import products from './home/products';

export default {
  components: {
    products,
  },
  data() {
    return {
      services: [],
      massage: [],
    };
  },

  created() {
   
   this.$http2.get("homeData").then(
      async (res) => {
        this.services =await res.data.services;
        this.massage = await res.data.massage;
        return await true;
      },
      (err) => {
        console.log(err);
      }
    );
  },
  
};
</script>
